import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AproposEntry from "../components/aProposEntry"

const Apropos = ({ location, data }) => {
	const entries = data.graphCmsAProposOrdered.allAPropos.map(entry => <AproposEntry entry={entry} key={entry.slug} />)
	return (
		<Layout location={location}>
			<SEO title="A propos" />
			{entries}
		</Layout>
	)
}

export default Apropos

export const AProposQuery = graphql`
query AProposQuery {
	graphCmsAProposOrdered {
		allAPropos {
			titre
			contenu {
				markdownNode {
					childMdx {
						body
					}
				}
			}
			slug
		}
	}
}
`

