import React from "react"

import { Row, Col } from "react-bootstrap" 

import { MDXRenderer } from "gatsby-plugin-mdx"

import MDXLayout from "./mdxlayout"


const AproposEntry = ({ entry }) => {
	return (
		<Row>
			<Col>
				<h2 className="anchor" id={entry.slug}>{entry.titre}</h2>
				<MDXLayout><MDXRenderer>{entry.contenu.markdownNode.childMdx.body}</MDXRenderer></MDXLayout>
			</Col>
		</Row>
	)
}

export default AproposEntry

